import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import service1Icon from "./../../../src/assets/icons/service1Icon.png";
import service2Icon from "./../../../src/assets/icons/service2Icon.png";
import service3Icon from "./../../../src/assets/icons/service3Icon.png";
import service4Icon from "./../../../src/assets/icons/service4Icon.png";
import "./style.css";

function CardsServices() {
  const buttonStyle = {
    backgroundColor: "rgb(233, 114,8)",
    borderColor: "rgba(255, 165, 0, 0.8)",
    color: "#fff",
  };
  return (
    <Container>
      <div className="hcard">
        <h3 className="h3card">SERVIÇOS</h3>
        <h4 className="h4card">Conheça nossas soluções</h4>
      </div>
      <Row className="servicerow">
        <Col>
          <Card className="card">
            <Card.Img variant="top" src={service1Icon} className="card-img-top" />
            <Card.Body>
              <Card.Title className="card-title">Serviço 1</Card.Title>
              <Card.Text className="card-text">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
              <Button
                variant="primary"
                className="btn btn-block"
                style={buttonStyle}
              >
                Saiba mais
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="card">
            <Card.Img variant="top" src={service2Icon} className="card-img-top" />
            <Card.Body>
              <Card.Title className="card-title">Serviço 2</Card.Title>
              <Card.Text className="card-text">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
              <Button variant="primary" className="btn btn-block"
                style={buttonStyle}>
                Saiba mais
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="card">
            <Card.Img variant="top" src={service3Icon} className="card-img-top" />
            <Card.Body>
              <Card.Title className="card-title">Serviço 3</Card.Title>
              <Card.Text className="card-text">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
              <Button variant="primary" className="btn btn-block"
                style={buttonStyle}>
                Saiba mais
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="card">
            <Card.Img variant="top" src={service4Icon} className="card-img-top" />
            <Card.Body>
              <Card.Title className="card-title">Serviço 4</Card.Title>
              <Card.Text className="card-text">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
              <Button variant="primary" className="btn btn-block"
                style={buttonStyle}>
                Saiba mais
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default CardsServices;
