import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo1 from "./../../../src/assets/logo/logoHeader2.png";
import logo2 from "./../../../src/assets/logo/logofooter.svg";
import logo3 from "./../../../src/assets/logo/logoHeader2.png";


import './style.css';
import { Carousel, Col, Container, Row } from "react-bootstrap";

function ClientCarousel() {

  const logos = [logo1, logo1, logo1, logo1, logo1, logo1, logo1, logo1]

  const renderLogos = () => {
    let items = [];
    for (let i = 0; i < logos.length; i += 4) {
      items.push(
        <Carousel.Item key={i}>
          <Container>
            <Row>
              {logos.slice(i, i + 4).map((logo, index) => (
                <Col xs={6} md={3} key={index}>
                  <img className="d-block w-100 mb-4" src={logo} alt={`Logo ${index + 1}`} />
                </Col>
              ))}
            </Row>
          </Container>
        </Carousel.Item>
      )
    }
    return items;
  };

  return (
    <>

      <div className="hservice">
        <h3 className="h3service">NOSSOS CLIENTES</h3>
      </div>

      <div className="custom-containercliente">
        <div className="custom-barcliente">
          <br />
          <h2 className="carousel-title-inside">Empresas que confiam na Speranza</h2>
          <Carousel id="meu-carousel" interval={2000} indicators={false} pause={false} className="carouselclient">
            {renderLogos()}
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default ClientCarousel;