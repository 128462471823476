import React from 'react';

function MapsLocation() {
  const latitude = -25.9670446;
  const longitude = 32.5852573;

  const mapsURL = `https://maps.google.com/maps?q=${latitude},${longitude}&t=&z=20&ie=UTF8&iwloc=&output=embed`;

  return (
    <div style={{ alignContent: "center", alignItems: "center", textAlign: "center", display: "flex", flexDirection: "column" }}>
      <div id="map-container-google-1" className="z-depth-1-half map-container" style={{ width: "100%", height: "500px", position: "relative" }}>
        <iframe src={mapsURL} frameBorder="0" style={{ border: "0", width: "95%", height: "100%" }}></iframe>
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -10%)", backgroundColor: "white", padding: "20px", borderRadius: "5px" }}>
          <h2>Maputo</h2>
          <p>Rua da Alegria, Polana Cimento.</p>
          <p>+258 847 440 000</p>
          <p>comercial@speranza.co.mz</p>
        </div>
      </div>
    </div>
  );
}

export default MapsLocation;
