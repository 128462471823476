import Badge from 'react-bootstrap/Badge';
import './style.css';

function WhoWeAreContent() {
  return (
    <div className="centered-text">
      <h4 className="orange-heading">
        QUEM SOMOS
      </h4>
      <h5 className="black-bold-heading">
        <span className="spaced-text"><span className="orange-plus">+</span>5 Anos</span>
        <span className="spaced-text"><span className="orange-plus">+</span>75 Projectos</span>
        <span className="spaced-text"><span className="orange-plus">+</span>3 Prêmios</span>
      </h5>

      <div className="text-saiba">
        Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source
      </div>

      <button className="saiba-btn">Saiba mais</button>
    </div>
  );
}

export default WhoWeAreContent;
