import React, { useEffect, useState } from 'react';
import logoHeader from "../../../assets/logo/logoHeader.svg"
import './style.css';

function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector('.navbar');
      if (window.scrollY > 0) {
        navbar.classList.add('bg-green');
      } else {
        navbar.classList.remove('bg-green');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleLinkClick = () => {
    setIsToggled(false);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top">
      <div className="container">
        <a className="navbar-brand" href="#">
          <img src={logoHeader} alt="Logo" />
        </a>
        <button
          style={{ backgroundColor: "white" }}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleNav}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse justify-content-center${isNavOpen ? ' show' : ''}`} id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" style={{ color: "white" }} href="#home" onClick={handleLinkClick}>Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" style={{ color: "white" }} href="#services" onClick={handleLinkClick}>Services</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" style={{ color: "white" }} href="#portfolio" onClick={handleLinkClick}>Portfolio</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" style={{ color: "white" }} href="#blog" onClick={handleLinkClick}>Blog</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" style={{ color: "white" }} href="#contacts" onClick={handleLinkClick}>Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
