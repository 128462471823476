import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/landingPage";


function AppRoutes() {

    return (
        <Router>
            <Routes >
                <Route path="/" element={<LandingPage />} exact />
            </Routes>
        </Router>
    )
}

export default AppRoutes;
