import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaInstagram, FaFacebookF, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';
import { IoMdSend } from "react-icons/io";
import logoFooter from "../../../assets/logo/logofooter.svg"
import "./style.css"

const Footer = () => {

  return (

    <footer style={{ backgroundColor: 'rgb(233, 114,8)', color: 'white' }}>
      <Container style={{}}>
        <br />
        <Row style={{}} >
          <Col className='first-col-details'>
            <div className='logocontainer'>
              <img src={logoFooter} alt="Logo" />
            </div>
            <p style={{ textDecoration: 'none', color: 'white' }}></p>

            <div className='main-social-networks'>
              <div className='social-networks-icons' >
                <FaInstagram style={{ color: 'white', fontSize: '20px' }} />
              </div>
              <div className='social-networks-icons'>
                <FaFacebookF style={{ color: 'white', fontSize: '20px' }} />
              </div>
              <div className='social-networks-icons'>
                <FaLinkedinIn style={{ color: 'white', fontSize: '20px' }} />
              </div>
              <div className='social-networks-icons'>
                <FaWhatsapp style={{ color: 'white', fontSize: '20px' }} />
              </div>
            </div>
          </Col>
          <Col className='third-col'>
            <ul style={{ listStyleType: 'none' }}>
              <li className='li-details' ><h4><strong>Suporte</strong></h4></li>
              <li className='li-details' ><a className='support-links' href="#" >Ajuda</a></li>
              <li className='li-details' ><a className='support-links' href="#" >Políticas de Privacidade</a></li>
              <li className='li-details' ><a className='support-links' href="#" >Sobre nós</a></li>
              <li className='li-details' ><a className='support-links' href="#" >Blog</a></li>
              <li className='li-details' ><a className='support-links' href="#" >Entrar em contacto</a></li>
            </ul>
          </Col>
          <Col className='fourth-col' >
            <h4>
              <strong>Entre em contacto</strong>
            </h4>
            <form>

              <div style={{ display: 'flex', marginBottom: '10px' }}>
                <input type="text" placeholder="Nome" style={{ border: 'none', background: 'none', borderBottom: '1px solid white', color: 'white', flex: 1, marginRight: '5px' }} />
                <input type="text" placeholder="Apelido" style={{ border: 'none', background: 'none', borderBottom: '1px solid white', color: 'white', flex: 1 }} />
              </div>

              <div style={{ display: 'flex', marginBottom: '10px' }}>
                <input type="email" placeholder="E-mail" style={{ border: 'none', background: 'none', borderBottom: '1px solid white', color: 'white', flex: 1, marginRight: '5px' }} />
                <input type="tel" placeholder="Número de celular" style={{ border: 'none', background: 'none', borderBottom: '1px solid white', color: 'white', flex: 1 }} />
              </div>

              <div style={{ display: "flex", marginBottom: "10px", }}>
                <textarea className='text-area-input' placeholder="Mensagem" style={{ border: 'none', background: 'none', borderBottom: '1px solid white', color: 'white', width: '90%', marginRight: "10px" }} ></textarea>
                {/* <button type="submit" className='btn btn-white rounded' style={{ background: 'white', border: '1px solid white', color: 'rgb(233, 114,8)', padding: '5px 10px', marginTop: '10px', float: "right", width: '20%', }}><RiSendPlaneFill /></button> */}
                <div style={{ textAlign: "center", alignContent: "center", alignItems: "center", width: "10%", display: "flex" }}>
                  <div className='button-send' style={{ width: "100%", }}>
                    {/* <RiSendPlaneFill style={{ fontSize: '2rem' }} /> */}
                    <IoMdSend style={{ fontSize: '2rem' }} />
                  </div>
                </div>
              </div>

              <input style={{ opacity: 0, backgroundColor: "red" }} type="text" placeholder="Campo invisível" />
              <input style={{ opacity: 0 }} type="text" placeholder="Campo invisível" />
            </form>
          </Col>
        </Row>
        <hr style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }} />
        <div className='maining-div'>
          <div className='centering-div' style={{ textAlign: 'center', alignContent: "center" }}>
            <ul style={{ listStyleType: 'none' }}>
              <div style={{ width: "100%" }}>Copyright &copy; {new Date().getFullYear()} Speranza Lda.</div>
            </ul>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;