import React from 'react'
import Navbar from '../components/layout/navbar/navbar'
import CardsServices from '../components/service/service'
import Footer from '../components/layout/footer/footer'
import MapsLocation from '../components/maps/maps'
import BlogAndPost from '../components/blog/blog'
import WhoWeAreContent from '../components/whowere/WhoWeAre'
import BarSpecialist from '../components/specialistbar/barraespecialista'
import ClientCarousel from '../components/client/clientCarousel'
import HeroSection from '../components/herosection/herosect'

function LandingPage() {
  return (
    <>
      <Navbar />
      <HeroSection />
      <WhoWeAreContent />
      <BarSpecialist />
      <CardsServices />
      <ClientCarousel />
      <BlogAndPost />
      <MapsLocation />
      <Footer />
    </>
  )
}

export default LandingPage
