import React from 'react';
import './HeroSection.css';

function HeroSection() {
  return (
    <section className="hero-section" >
      <div className="hero-content" >
        <h1 className="hero-title">BEM-VINDO</h1>
        <p className="hero-text"> It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content here', making it
          look like readable English.</p>
          <button className="solicitar-btn">SOLICITAR ORÇAMENTO</button>
      </div>
    </section>
  );
}

export default HeroSection;
