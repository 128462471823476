import peopleBlog3 from "./../../../src/assets/images/peopleBlog3.png";
import peopleBlog1 from "./../../../src/assets/images/peopleBlog1.png";
import peopleBlog2 from "./../../../src/assets/images/peopleBlog2.png";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col } from "react-bootstrap";
import './style.css';


function BlogAndPost() {


  return (
    <>
      <div className="hblog">
        <Row >
          <Col >
            <h3 className="h3blog">BLOG</h3>
          </Col>
          <Col>
            <h4 className="h4blog">
              <a className='linkblog' href="#yourLink">Todos os posts </a>
            </h4>
          </Col>
        </Row>
      </div>

      <div className="d-flex justify-content-between " >

        <div className="cardblog" style={{ width: "18rem" }}>
          <img src={peopleBlog1} className="img-fluid" alt="Card image cap" />
          <div className="main-text-bellow" >
            <div className="main-text-left" >INFRASTRUTURA</div>
            <div className="main-text-right" >Sunday, Jan 2023</div>
          </div>
          <div className="main-text-left">
            <h5 className=""><strong>Migrating to Linear </strong></h5>
            <p className="card-text">
              Linear helps streamline softwareprojects, sprints, tasks, and bugtracking. Here's how to get.
            </p>
            <a href="#" className="main-text-left">Ler mais... </a>
          </div>
        </div>


        <div className="cardblog2" style={{ width: "18rem" }}>
          <img src={peopleBlog2} className="img-fluid" alt="Card image cap" />
          <div className="main-text-bellow" >
            <div className="main-text-left" >TECNOLOGIAS</div>
            <div className="main-text-right" >Sunday, Jan 2023</div>
          </div>
          <div className="main-text-left">
            <h5 className=""><strong>Migrating to Linear </strong></h5>
            <p className="card-text">
              Linear helps streamline softwareprojects, sprints, tasks, and bugtracking. Here's how to get.
            </p>
            <a href="#" className="main-text-left">Ler mais... </a>
          </div>
        </div>

        <div className="cardblog3" style={{ width: "18rem" }}>
          <img src={peopleBlog3} className="img-fluid" alt="Card image cap" />
          <div className="main-text-bellow" >
            <div className="main-text-left" >PROGRAMACAO</div>
            <div className="main-text-right" >Sunday, Jan 2023</div>
          </div>
          <div className="main-text-left">
            <h5 className=""><strong>Migrating to Linear </strong></h5>
            <p className="card-text">
              Linear helps streamline softwareprojects, sprints, tasks, and bugtracking. Here's how to get.
            </p>
            <a href="#" className="main-text-left">Ler mais... </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogAndPost;
