import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import logoPersonLaptop from './../../../src/assets/logo/logoPersonLaptop.png';

const BarSpecialist = () => {
  return (
    <Container fluid className="custom-container">
      <Row className="custom-bar" >
        <Col lg={6} className="custom-content">
          <h5>Transformamos desafios em soluções. Sua jornada para a excelência começa aqui</h5>
          <button className="solicitar-btn">Converse com um especialista</button>
          {/* <Button variant="primary " className='botaoespecialista'>Converse com um especialista</Button> */}
        </Col>
        <Col lg={6} className="bar-image-container" >
          <img src={logoPersonLaptop} alt="Imagem na barra" className="bar-image" />
        </Col>
      </Row>
    </Container>

  );
}

export default BarSpecialist;
